<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 搜索 -->
      <OrderSearch :showOrderStatus="'order_info'" @getSearchData="getSearchData" ref="OrderSearch"></OrderSearch>
      <div>
        <a-button v-if="isAdd" type="primary" @click="toHandler('add')">添加订单</a-button>
        <!--            <a-button v-if="isEditAddress && selectedRows.length > 0" type="primary" @click="toHandler('edit_address')">修改地址</a-button>-->
        <!-- <a-button v-if="isEditInvoice && selectedRows.length > 0" type="primary" @click="toHandler('edit_invoice')">修改发票</a-button> -->
        <a-button v-if="isEdit && selectedRows.length == 1 && selectedRows[0].orderStatus == 9" type="primary" @click="toHandler('edit')">修改订单</a-button>
        <a-button v-if="isQuick && selectedRows.length == 1" type="primary" @click="toHandler('quick')">复制订单</a-button>
        <!--            <a-button v-if="isAudit && (selectedRows.length > 0 && selectedRows[0].orderStatus != 3)" type="primary" @click="toHandler('audit')">审核</a-button>-->
        <!--            <a-button v-if="selectedRows.length > 0 && selectedRows[0].orderStatus == 4" type="primary" @click="toHandler('generate_invoice')">生成发货单</a-button>-->
        <!--            <a-button v-if="isVoid && selectedRows.length > 0" type="primary" @click="toHandler('void')">作废</a-button>-->
        <a-button v-if="isCancel && selectedRows.length === 1" type="primary" @click="toHandler('cancel')">取消订单</a-button>
        <a-button v-if="isCheck && selectedRows.length === 1" type="primary" @click="toHandler('check')">查看</a-button>
        <a-button
          v-if="isExportLGContract && selectedRows.length == 1 && selectedRows[0].linkGapContractNum != null && selectedRows[0].confirmDiscount"
          @click="downHt('lght')"
          icon="download"
        >下载销售合同</a-button>
        <a-button v-if="isDelete && selectedRows.length === 1 && selectedRows[0].orderStatus == 9" type="danger"
                  @click="toHandler('del')">删除
        </a-button>
        <a-button
          v-if="isAudit && selectedRows.length === 1 && selectedRows[0].orderStatus == 0"
          type="default"
          @click="toHandler('rollbackStatus')"
          icon="rollback">撤审
        </a-button>
        <downLoad
          v-if="isExport"
          method="post"
          api="/api/order/order/orderInfo/excelExportDealerOrderList"
          :params="searchDataObj"
          name="经销商采购单.xls">批量导出订单
        </downLoad>
      </div>
      <!-- 数据表格 -->
      <OrderList
        :url="'/api/order/order/orderInfo/dealerOrderList'"
        :columnsData="columns"
        :status="[]"
        @orderData="getSelectedRows($event)"
        @orderDataKey="getKeys"
        @changeTable="getchangeTable"
      ></OrderList>

      <!-- 编辑表单 -->
      <OrderInfo-edit-modal ref="OrderInfoEditModal" @getConfirmDiscount="getConfirmDiscount"></OrderInfo-edit-modal>
      <orderInfoQuick ref="orderInfoQuick" @getConfirmDiscount="getConfirmDiscount"></orderInfoQuick>
      <EditAddressModal ref="EditAddressModal"></EditAddressModal>
      <InvoiceEditModal ref="InvoiceEditModal"></InvoiceEditModal>
      <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
      <GenerateInvoice ref="GenerateInvoice"></GenerateInvoice>

      <a-modal
        title="操作"
        width="30%"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :footer="null"
        @cancel="visible = false"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <div v-if="handle == 'audit'">
            <a-form-model-item label="是否通过">
              <a-radio-group v-model="status" @change="onStatusChange">
                <a-radio :value="4"> 通过</a-radio>
                <a-radio :value="3"> 拒绝</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="原因" v-if="statusRadio == 3">
              <a-textarea v-model="rejectReason"></a-textarea>
            </a-form-model-item>
          </div>
          <div v-if="handle == 'cancel' || handle == 'void'">
            <a-form-model-item label="理由">
              <a-textarea v-model="rejectReason"></a-textarea>
            </a-form-model-item>
          </div>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="visible = false">关闭</a-button>
          <a-button v-if="handle == 'void'" type="primary" @click="onModifyOrderStatus('void')">作废</a-button>
          <a-button v-if="handle == 'cancel'" type="primary" @click="onModifyOrderStatus('cancel')">取消订单</a-button>
          <a-button v-if="handle == 'audit' && statusRadio == 3" type="danger" @click="onModifyOrderStatus('refuse')"
          >确定
          </a-button
          >
          <a-button v-if="handle == 'audit' && statusRadio == 4" type="primary" @click="onModifyOrderStatus('audit')"
          >确定
          </a-button
          >
        </div>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import OrderInfoEditModal from '@/views/order_info/components/OrderInfoEditModalExplabe.vue'
import orderInfoQuick from './components/orderInfoQuick.vue'
import {delOrderInfo, rollbackStatus} from './api/OrderInfoApi'
import EditAddressModal from './components/EditAddressModal.vue'
import InvoiceEditModal from './components/InvoiceEditModal.vue'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal.vue'
import {checkPermission} from '@/utils/permissions'
import GenerateInvoice from '../to_delivery_order/components/GenerateInvoice.vue'

export default {
  name: 'orderInfo',
  components: {
    OrderInfoEditModal,
    orderInfoQuick,
    EditAddressModal,
    InvoiceEditModal,
    OrderInfoCheckModal,
    GenerateInvoice,
  },
  data() {
    return {
      columns: [
        'orderCode',
        'linkGapContractNum',
        'stContractNum',
        'bigAreaName',
        'dealerName',
        'contractType',
        'orderType',
        'sourceType',
        'payAmount',
        'orderStatus',
        'createTime',
        'orderDate',
        'address',
        'consigneeType',
        'supplierName',
        'deliveryProperties',
        'expectTime',
        'purchaseAndSalesContractType',
        'flagFreeShipping',
        'policyTotalAmount',
        'rebateTotalAmount',
        'alreadySendGoodsCount',
        'bucklePoint',
        'goodsCount'
      ],
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: {y: 600},
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      isAdd: checkPermission('order:info:add'),
      isEdit: checkPermission('order:info:edit'),
      isQuick:checkPermission('order:info:isQuick'),
      isDelete: checkPermission('order:info:delete'),
      isEditAddress: checkPermission('order:info:edit_address'),
      isEditInvoice: checkPermission('order:info:edit_invoice'),
      isCheck: checkPermission('order:info:check'),
      isAudit: checkPermission('order:info:audit'),
      isCancel: checkPermission('order:info:cancel'),
      isVoid: checkPermission('order:info:void'),
      isExport: checkPermission('order:info:export'),
      isExportLGContract: checkPermission('order:info:export_lg_contract_audit_finish'),
      visible: false,
      confirmLoading: false,
      rejectReason: '',
      handle: '',
      contractTypeList: [],
      orderTypeList: [],
      status: 4,
      statusRadio: 4,
      idsList:[]
    }
  },
   computed:{
    searchDataObj(){
      let obj = {
        ...this.searchData,
        ids:this.idsList
      }
      console.log("obj",obj)
      return obj

    }
  },
  methods: {
    getchangeTable(){
      this.$refs.OrderSearch.getDataFilter(1)
    },
     getConfirmDiscount(){
      this.$refs.OrderSearch.getDataFilter()
    },
       // 导出条件
    getSearchData(row){
     this.searchData = row
     this.idsList = []
    },
    getKeys(row){
     this.idsList = row
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },

    getSelectedRows(rows) {
      this.selectedRows = rows
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.OrderInfoEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.OrderInfoEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'quick':
          _this.$refs.orderInfoQuick.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delOrderInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({message: res.message})
                _this.$refs.OrderSearch.getDataFilter()
              } else {
                _this.$notification.error({message: res.message})
              }
            },
            onCancel() {
            },
          })
          break
        case 'rollbackStatus':
          _this.$confirm({
            title: '撤回警告',
            content: '确认要撤回此条记录的提交审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await rollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({message: res.message})
                _this.$refs.OrderSearch.getDataFilter()
              } else {
                _this.$notification.error({message: res.message})
              }
            },
            onCancel() {
            },
          })
          break
        case 'edit_address':
          _this.$refs.EditAddressModal.isShow(_this.selectedRows[0])
          break
        case 'edit_invoice':
          _this.$refs.InvoiceEditModal.isShow(_this.selectedRows[0])
          break
        case 'audit':
          _this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0], 'audit')
          break
        case 'void':
          _this.visible = true
          _this.handle = 'void'
          break
        case 'cancel':
          _this.visible = true
          _this.handle = 'cancel'
          break
        case 'check':
          _this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0], 'check')
          break
        case 'generate_invoice':
          _this.$refs.GenerateInvoice.setRowData(_this.selectedRows[0], 'generate')
          break
      }
    },

    // 修改订单状态
    onModifyOrderStatus(name) {
      if (name == 'audit') {
        this.onModifyStatus(4, 'audit')
      } else if (name == 'refuse') {
        this.onModifyStatus(3, 'refuse')
      } else if (name == 'void') {
        this.onModifyStatus(1, 'void')
      } else if (name == 'cancel') {
        this.onModifyStatus(2, 'cancel')
      }
    },
    downHt(flag) {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      var path = ''
      if(flag === 'lght'){
        path = _this.selectedRows[0].linkGapContractUrl
      }else{
        path = _this.selectedRows[0].stContractUrl
      }
      this.axios.get('/api/order/order/orderInfo/checkContractExist?path='+path).then((res) => {
        if (res.code == 200) {
          debugger
          if(res.body == 200){
            const routeData = this.$router.resolve({
              path: '/api/order/order/orderInfo/contract/download',
              query: {
                orderId: _this.selectedRows[0].id,
                flag: flag,
              },
            })

            window.open(routeData.href, '_blank')
          }else{
            _this.$notification.warning({
              message: '合同丢失了 ，请联系管理员重新生成',
            })
            return
          }

        }
      })

    },

    // 调用修改状态对应接口
    onModifyStatus(status, name) {
      let obj = {
        orderId: this.selectedRows[0].id,
        verifyRemark: this.rejectReason,
      }
      let url =
        name === 'cancel'
          ? '/api/order/order/orderInfo/cancelOrderInfo'
          : name === 'void'
          ? '/api/order/order/orderInfo/invalidOrderInfo'
          : '/api/order/order/orderInfo/verifyOrderInfo'
      this.axios.post(url, obj).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visible = false
          this.rejectReason = ''
          this.$refs.OrderSearch.getDataFilter()
          this.selectedRowKeys = []
        }
      })
    },

    onStatusChange(val) {
      this.statusRadio = val.target.value
    },
  },
  created() {
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}

::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}

::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}

.footer-bts {
  text-align: right;
}
</style>
